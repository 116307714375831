import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    config.baseURL = 'https://potasyyum.xyz';

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {

      }
    }
    return Promise.reject(error);
  }
);