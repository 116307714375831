import { ReactNode } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Link,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

interface PricingItem {
  title : string,
  mostPopular : Boolean,
  price : {
    currency : string,
    value : string,
  },
  featureList : [
    {
      featureTitle : string
    }
  ],
}

const PRICING_ITEMS : Array<PricingItem> = [
  {
    title : 'Paket Sayang',
    mostPopular : false,
    price : {
      currency : 'Rp',
      value : '350',
    },
    featureList : [
      {
        featureTitle :'Foto Sampul'
      },
      {
        featureTitle :'Countdown Timer'
      },
      {
        featureTitle :'Galeri Foto (6 Foto)'
      },
      {
        featureTitle :'Google Maps'
      },
      {
        featureTitle :'Friend Wishes'
      }
    ]
  },
  {
    title : 'Paket Cinta',
    mostPopular : true,
    price : {
      currency : 'Rp',
      value : '500',
    },
    featureList : [
      {
        featureTitle :'Foto Sampul'
      },
      {
        featureTitle :'Countdown Timer'
      },
      {
        featureTitle :'Galeri Foto'
      },
      {
        featureTitle :'Google Maps'
      },
      {
        featureTitle :'Friend Wishes'
      },
      {
        featureTitle :'Google Calendar'
      },
      {
        featureTitle :'Halaman buku tamu'
      },
      {
        featureTitle :'Background Music'
      },
      {
        featureTitle :'RSVP'
      },
      {
        featureTitle :'Angpao Online (Gopay, OVO, QRIS)'
      },
      {
        featureTitle :'Bebas Revisi'
      }
    ]
  },
  {
    title : 'Custom',
    mostPopular : false,
    price : {
      currency : 'Rp',
      value : '900',
    },
    featureList : [
      {
        featureTitle :'Domain custom'
      },
      {
        featureTitle :'Semua fitur di paket Cinta'
      },
    ]
  },
]

function PriceWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', lg: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

function PricingSection() {
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const mostPopularBg = useColorModeValue('red.300', 'red.700');
  const mostPopularColor = useColorModeValue('gray.900', 'gray.300');
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      textAlign="center"
      justify="center"
      spacing={{ base: 4, lg: 10 }}
      py={10}>
        {PRICING_ITEMS.map((item) => (
          <PriceWrapper>
            <Box position="relative">
            {item.mostPopular ? (
              <Box
                position="absolute"
                top="-16px"
                left="50%"
                style={{ transform: 'translate(-50%)' }}>
                <Text
                  textTransform="uppercase"
                  bg={mostPopularBg}
                  px={3}
                  py={1}
                  color={mostPopularColor}
                  fontSize="sm"
                  fontWeight="600"
                  rounded="xl">
                  Most Popular
                </Text>
              </Box>
            ) : (<Box/>)}
              <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                  {item.title}
                </Text>
                <HStack justifyContent="center">
                  <Text fontSize="3xl" fontWeight="600">
                    {item.price.currency}
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    {item.price.value}
                  </Text>
                  <Text fontSize="3xl" color="gray.500">
                    k
                  </Text>
                </HStack>
              </Box>
              <VStack
                bg={bgColor}
                py={4}
                borderBottomRadius={'xl'}>
                <List spacing={3} textAlign="start" px={12}>
                  {item.featureList.map((featureItem) => (
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      {featureItem.featureTitle}
                    </ListItem>
                  ))}
                </List>
                <Box w="80%" pt={7}>
                  <Button 
                    as={Link}
                    w="full"
                    colorScheme="amber"
                    href={"https://momerit.id/form.html?paket=sayang"}
                    variant={item.mostPopular ? "solid" : "outline"}>
                    Pilih Paket
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PriceWrapper>
        ))}
    </Stack>
  );
}

export default function Pricing() {
  return (
    <Box py={12} id="pricing">
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Paket Undangan
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Tenang aja, kamu bisa mengubah fitur yang terdapat dalam paket, harga akan otomatis menyesuaikan jumlah fiturnya
        </Text>
      </VStack>
      <PricingSection/>
    </Box>
  );
}