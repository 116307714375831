import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import Landing  from '../pages/landing'
import Peerpages from '../pages/peerpages';
import Peersend from '../pages/peersend';
import { NotFound } from '../components/NotFound';

export default function AppRoutes() {
    return useRoutes(
        [
            {
                path: '/',
                element: <Landing />
            },
            {
              path: '/p',
              element: <Peerpages />,
            },
            {
              path: '/s',
              element: <Peersend />,
            },
            {
              path: '*',
              element: <NotFound />,
            },
            
        ]
    )
}