import React, {useState} from 'react';
import {
  VStack,
  Text,
  Center,
  Box,
} from '@chakra-ui/react';
import { Peer } from "peerjs";

export default function Peerpages(){
  const [value, setValue] = useState('');
  const [streamVid, setStreamVid] = useState(null);
  const peer = new Peer("739e520f-c23a-4ef9-ba3f-a505729adef2",{host:'potasyyum.xyz',path:'/p/'});
  // const peer = new Peer("ekhiw-3559-01");
  peer.on("connection", (conn) => {
    conn.on("data", (data) => {
      // Will print 'hi!'
      console.log(JSON.stringify(data));
      setValue(data);
    });
    conn.on("open", () => {
      console.log("open");
      navigator.mediaDevices.getUserMedia({audio:true,video:true}, function(stream) {
        stream.getTracks().forEach(x=>x.stop());
      }, err=>console.log(err));
    });
  });

  peer.on("call", (call) => {
    navigator.mediaDevices.getUserMedia(
      { video: true, audio: true },
      (stream) => {
        call.answer(stream); // Answer the call with an A/V stream.
        call.on("stream", (remoteStream) => {
          // Show stream in some <video> element.
          setStreamVid(remoteStream);
        });
      },
      (err) => {
        console.error("Failed to get local stream", err);
      },
    );
  });
  return (
    <Center w={'100%'} h={'100%'}>
      <VStack mt={32} spacing={4}>
        <Text>Hello world!</Text> 
        <Text>{value}</Text>
        <Box w={480} h={360}>
          <video src={streamVid} autoPlay></video>
        </Box>
      </VStack>
    </Center>
  )
}