import React from 'react';
import {Image, useColorMode} from '@chakra-ui/react';

const LogoInline = props => {

  const { colorMode, toggleColorMode } = useColorMode();

  if (colorMode === 'light') {
    return (
      <Image src={"/logo-inline-black.png"} {...props} />
    );
  } else {
    return (
      <Image src={"/logo-inline-white.png"} {...props} />
    );
  }
};

const Logo = props => {

  return <Image src={"/logo720.png"} {...props} />;
};

export { LogoInline,Logo };
