import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  useColorModeValue,
  Circle,
  Tooltip,
  Link,
} from '@chakra-ui/react';
import {CheckIcon} from '@chakra-ui/icons';
import { 
  FaImage,
  FaImages,
  FaHourglassHalf,
  FaMapMarkedAlt,
  FaRegCommentDots,
  FaCalendarCheck,
  FaReadme,
  FaMusic,
  FaBookmark,
  FaMoneyCheckAlt
} from "react-icons/fa";

interface FeatureItem {
  id: string;
  title?: string;
  text?: string;
  icon?: React.ReactElement;
  iconColor?: string;
  isPremium?: boolean;
}

const FEAT_ITEMS: Array<FeatureItem> = [
  {
    id: '1',
    title: 'Foto Sampul',
    text: 'Tampilkan foto terbaik anda sebagai foto sampul',
    icon: FaImage,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '2',
    title: 'Countdown Timer',
    text: 'Penghitung waktu mundur sebagai pengingat acara untuk tamu undangan',
    icon: FaHourglassHalf,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '3',
    title: 'Galeri Foto',
    text: 'Galeri untuk foto prewedding anda',
    icon: FaImages,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '4',
    title: 'Google Maps',
    text: 'Google maps custom untuk memudahkan tamu anda menemukan lokasi acara',
    icon: FaMapMarkedAlt,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '5',
    title: 'Friend Wishes',
    text: 'Sebuah kolom untuk tamu anda mengucapkan selamat dan doa',
    icon: FaRegCommentDots,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '6',
    title: 'Google Calendar',
    text: 'Integrasi dengan google calendar untuk memunculkan notifikasi pengingat 1 minggu sebelum acara',
    icon: FaCalendarCheck,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '7',
    title: 'Halaman buku tamu',
    text: 'Sebuah dashboard untuk melihat buku tamu dan ucapan',
    icon: FaReadme,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '8',
    title: 'Background Music',
    text: 'Beri kesan elegan dan menyenangkan untuk tamu',
    icon: FaMusic,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '9',
    title: 'RSVP',
    text: 'Reservasi kehadiran tamu sebagai perkiraan jumlah tamu yang akan datang ke acara',
    icon: FaBookmark,
    iconColor: 'green.500',
    isPremium: false,
  },
  {
    id: '10',
    title: 'Angpao Online (Gopay, OVO, QRIS)',
    text: 'Sarana angpao cashless untuk tamu anda',
    icon: FaMoneyCheckAlt,
    iconColor: 'green.500',
    isPremium: false,
  },
];

export default function Features() {

  const bgColor = useColorModeValue('white', 'gray.900');
  return (
    <Box p={4} id="feature">
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>Fitur Undangan</Heading>
        <Text color={'gray.600'} fontSize={'xl'}>
          Yuk lihat fitur yang kami punya untuk undanganmu!
        </Text>
      </Stack>
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{base: 1, md: 2, lg: 4}} spacing={10}>
          {FEAT_ITEMS.map((feature) => (
            <Box
              bg={bgColor}
              boxShadow={'md'}
              rounded={'lg'}
              p={6}
              border={'2px solid'}
              borderColor={feature.isPremium ? '#A48111' : '#6B7280'}
              position="relative">
              {/*<Tooltip*/}
              {/*  label={feature.isPremium ? 'Fitur Premium' : 'Fitur Standart'}*/}
              {/*  bg="white"*/}
              {/*  placement={'top-end'}*/}
              {/*  color={'gray.800'}*/}
              {/*  fontSize={'1.2em'}>*/}
              {/*  <Link href={'#'} display={'flex'}>*/}
              {/*    <Circle*/}
              {/*      size="10px"*/}
              {/*      position="absolute"*/}
              {/*      top={2}*/}
              {/*      right={2}*/}
              {/*      zIndex={999}*/}
              {/*      bg={feature.isPremium ? '#A48111' : '#6B7280'}/>*/}
              {/*  </Link>*/}
              {/*</Tooltip>*/}
              <VStack key={feature.id} align={'center'} textAlign={'center'}>
                <Box color={'green.400'} px={2}>
                  <Icon as={feature.icon}/>
                </Box>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text color={'gray.600'}>{feature.text}</Text>
              </VStack>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}