import React from 'react';
import {
  VStack,
} from '@chakra-ui/react';
import {Hero, NavBar, Footer, Features, Pricing, TemplateList} from '../../components';
import { Navigate, useSearchParams } from "react-router-dom";

export default function Landing(){

  const [searchParams] = useSearchParams();
  const dir = searchParams.get("dir");

  if(dir == "s"){
    return <Navigate to="/s" replace={true} />;
  }
  if(dir == "p"){
    return <Navigate to="/p" replace={true} />;
  }


  return (
    <VStack>
      <NavBar />
      <Hero />
      <Features />
      <Pricing />
      <TemplateList />
      <Footer/>
    </VStack>
  )
}