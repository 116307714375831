import React, { useState, useEffect, } from 'react';
import {
  VStack,
  HStack,
  Text,
  Input,
  Box,
  Button,
  Center,
} from '@chakra-ui/react';
import { Peer } from "peerjs";
import axios from 'axios';

const PeerInstance = (props) => {
    const [value, setValue] = useState('');
    const handleChange = (event) => setValue(event.target.value)

    const { peerId } = props;
    const peer = new Peer(peerId,{host:'potasyyum.xyz',path:'/p/'});
    
    const conn = peer.connect("739e520f-c23a-4ef9-ba3f-a505729adef2");
    
    conn.on("open", () => {
        console.log("OPEN DONG BGST")
        conn.send("AYAM");
        navigator.mediaDevices.getUserMedia({audio:true,video:true}, function(stream) {
            stream.getTracks().forEach(x=>x.stop());
          }, err=>console.log(err));
    });
    return (
        <VStack spacing={6}>
            <HStack spacing={4}>
                <Input
                    value={value}
                    onChange={handleChange} 
                    type={'text'}
                    size='sm'
                    disabled
                    placeholder={'Test'}/>
                <Button 
                    onClick={() => {
                        conn.send(`${new Date()}`);
                    }}
                >
                    Send
                </Button>
            </HStack>
            <Button 
                onClick={() => {
                    navigator.mediaDevices.getUserMedia(
                        { video: true, audio: true },
                        (stream) => {
                            const call = peer.call("739e520f-c23a-4ef9-ba3f-a505729adef2", stream);
                            call.on("stream", (remoteStream) => {
                                // Show stream in some <video> element.
                            });
                        },
                        (err) => {
                            console.error("Failed to get local stream", err);
                        },
                    );
                }}
            >
                Send
            </Button>

        </VStack>
    )
}

export default function Peersend(){
    const [peerId, setPeerId] = useState(null);

    useEffect(() => {
      if(peerId == null) {
        axios.get('/p/peerjs/id')
            .then( (response) => {
                console.log(`id = ${response.data}`);
                setPeerId(response.data);
            });
      }
    })

    return (
        <Center w={'100%'} h={'100%'}>
        <VStack>
            <Text>Hello world!</Text> 
            <Text>My id : {peerId}</Text> 
            {peerId != null ? <PeerInstance peerId={peerId}/> : <Box></Box> }
        </VStack>
        </Center>
    )
}