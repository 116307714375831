import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import Landing from './pages/landing/index.js';
import AppRoutes from './routes'
import { BrowserRouter } from "react-router-dom";

import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  colors: {
    amber: {
      50: "#FFFBEB",
      100: "#FEF3C7",
      200: "#FDE68A",
      300: "#FCD34D",
      400: "#FBBF24",
      500: "#F59E0B",
      600: "#D97706",
      700: "#B45309",
      800: "#92400E",
      900: "#78350F",
    },
  },
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes/>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
