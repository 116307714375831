import React from 'react';
import {
  Box,
  Text,
  Link,
  VStack,
  Heading,
  Container,
  SimpleGrid,
} from '@chakra-ui/react';
import TemplateCard from './TemplateCard';

export default function TemplateList() {

  return (
    <Box py={12} id="template">
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Template Undangan
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Tersedia beberapa template undangan yang siap anda gunakan.
        </Text>
      </VStack>
      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{base: 1, md: 3, lg: 3}} spacing={10}>
          <TemplateCard />
          <TemplateCard />
          <TemplateCard />
        </SimpleGrid>
      </Container>
    </Box>
  );
}